import Container from "../../../components/Container";
import { kekData } from "../../../mocks/kekData";
import { BackgroundImage, Section } from "./style";
import ContactLink from "../../../components/ContactLink";

export default function Contact() {
  return (
    <BackgroundImage>
      <Container>
        <Section>
          <div>
            <p>Precisa de ajuda para decidir seu roteiro?</p>
            <p className="sub-texto">
              Estamos aqui para ajudar você a realizar a viagens dos seus sonhos
            </p>
            <ContactLink
              title="fale no whatsapp"
              link={kekData.whatsapp}
              blank
            />
          </div>
        </Section>
      </Container>
    </BackgroundImage>
  );
}
