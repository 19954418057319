import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Section = styled.p`
  width: 100%;
  text-align: center;
  background-color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  color: #000;
  padding: 16px 0;

  a {
    color: ${COLORS.PRIMARY};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }
`;
