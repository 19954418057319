import { Section } from "./style";

export default function Copyright() {
  return (
    <Section>
      © 2024 Martins Viagens. Todos os direitos reservados. Feito por:{" "}
      <a href="https://www.tensortech.com.br/" target="_blank">
        Tensortech
      </a>
    </Section>
  );
}
