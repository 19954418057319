import Footer from "../../components/Footer";
import Header from "../../components/Header";
import About from "./About";
import Contact from "./Contact";
import Intro from "./Intro";
import Packages from "./Packages";
import SearchTrip from "./SearchTrip";
import Testimonies from "./Testimonies";
import { useRef } from "react";

export default function Home() {
  const topRef = useRef<any>(null);
  const packagesRef = useRef<any>(null);
  const aboutRef = useRef<any>(null);
  const testRef = useRef<any>(null);

  return (
    <>
      <Header
        topRef={topRef}
        packagesRef={packagesRef}
        aboutRef={aboutRef}
        testRef={testRef}
      />
      <SearchTrip referance={topRef} />
      <Packages referance={packagesRef} />
      <About referance={aboutRef} />
      <Testimonies referance={testRef} />
      <Contact />
      <Footer topRef={topRef} />
    </>
  );
}
