import { styled } from "styled-components";
import Family from "../../../assets/images/bg.png";
import { FONT_WEIGHT } from "../../../theme";

export const BackgroundImage = styled.div`
  background-image: url(${Family});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 64px 0;
    background-position: left;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 84px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 0;
  }

  div {
    width: 40%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1400px) {
      width: 35%;
    }

    @media (max-width: 950px) {
      width: 100%;
    }

    p {
      font-weight: ${FONT_WEIGHT.BOLD};
      font-size: 38px;
      color: #fff;
      margin-bottom: 48px;

      @media (max-width: 1400px) {
        font-size: 28px;
      }
    }

    .sub-texto {
      font-size: 24px;
      font-weight: 600;
    }
  }

  img {
    width: 40%;

    @media (max-width: 950px) {
      display: none;
    }
  }
`;
