import {
  BackgroundImage,
  CallToActionContainer,
  Title,
  Description,
  Button,
} from "./style";
import { SectionProps } from "../../../types/section";
import Container from "../../../components/Container";

export default function SearchTrip({ referance }: SectionProps) {
  return (
    <BackgroundImage ref={referance}>
      <Container>
        <CallToActionContainer>
          <Title>A VIAGEM DOS SEUS SONHOS VOCÊ ENCONTRA AQUI</Title>
          <Description>
            Conheça nossos pacotes de viagens e venha aproveitar o melhor de
            suas férias.
          </Description>
          <Button>FALE CONOSCO</Button>
        </CallToActionContainer>
      </Container>
    </BackgroundImage>
  );
}
