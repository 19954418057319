import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "../../../components/Container";
import { Dot, Dots, EmptyPackages, Section, Title, SubTitle } from "./style";
import { useCallback, useRef, useState } from "react";
import Slider from "react-slick";
import Slide from "./slide";
import { SlideType, packages } from "../../../mocks/packages";
import { SectionProps } from "../../../types/section";
import TabsSelection from "../../../components/TabsSelection";
import Modal from "../../../components/Modal";
import { PackagesProps } from "../../../types/packages";

export default function Packages({ referance }: SectionProps) {
  const [packType, setPackType] = useState<number>(SlideType.nacional);
  const [selectedPackage, setSelectedPackage] = useState<PackagesProps>();

  const sliderRef = useRef<any>(null);

  const changePackType = useCallback(
    (packNumber: number) => {
      setPackType(packNumber);
      sliderRef?.current.slickGoTo(0, true);
      if (packages.filter((pack) => pack.type === packNumber).length >= 3) {
        setPackType(packNumber);
        return;
      }
      setTimeout(() => {
        setPackType(packNumber);
      }, 1);
    },
    [packType]
  );

  const handlePackType = () => {
    return packages.filter((pack) => pack.type === packType);
  };

  const settings = {
    initialSlide: 0,
    dots: handlePackType().length > 0,
    infinite: handlePackType().length > 3,
    autoplay: handlePackType().length > 3,
    draggable: handlePackType().length > 3,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          slidesToShow: 2,
          dots: handlePackType().length > 2,
          infinite: handlePackType().length > 2,
          autoplay: handlePackType().length > 2,
          slidesToScroll: handlePackType().length > 2 ? 1 : 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: true,
          infinite: handlePackType().length > 1,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => (
      <Dots>
        <Dot> {dots} </Dot>
      </Dots>
    ),
  };

  function openModal(p: PackagesProps) {
    setSelectedPackage(p);
  }

  return (
    <>
      <Section ref={referance}>
        <Container>
          <Title>SUA VIAGEM</Title>
          <SubTitle>Pelo Brasil e pelo Mundo é com a Martins viagens</SubTitle>
          <TabsSelection
            value={packType}
            setValue={changePackType}
            options={["rodoviários", "nacionais", "internacionais"]}
          />
          {handlePackType().length > 0 ? (
            <Slider {...settings} ref={sliderRef}>
              {handlePackType().map((pack, index) => (
                <Slide
                  pack={pack}
                  props={index}
                  key={index}
                  setPackage={openModal}
                />
              ))}
            </Slider>
          ) : (
            <EmptyPackages>
              No momento não temos nenhum PACOTE desse tipo
            </EmptyPackages>
          )}
        </Container>
      </Section>
      <Modal
        selectedPackage={selectedPackage}
        open={!!selectedPackage}
        setOpen={setSelectedPackage}
      />
    </>
  );
}
