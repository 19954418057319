import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";

export const Section = styled.div`
  width: 100%;
  padding: 135px 0;
  background-color: ${COLORS.GRAY};

  @media (max-width: 1400px) {
    padding: 114px 0;
  }

  @media (max-width: 950px) {
    padding: 98px 0;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  color: ${COLORS.PRIMARY};

  @media (max-width: 1400px) {
    font-size: 28px;
  }
`;

export const TestimoniesBox = styled.div`
  width: 100%;
  margin-top: 48px;
`;

// SLIDER

export const Dots = styled.div`
  border-radius: 12px;
  padding: 12px 0;
`;

export const Dot = styled.ul`
  margin-bottom: -32px;

  li button:before {
    font-size: 12px;
    opacity: 1;
    color: #d7d7d7;
  }

  li.slick-active button:before {
    color: ${COLORS.PRIMARY};
    opacity: 1;
  }
`;

export const SlideBox = styled.div`
  width: 96%;
  background-color: ${COLORS.WHITE};
  box-shadow: 6px 6px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  img {
    width: 100%;
    border-radius: 10px;
  }
`;
