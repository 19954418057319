import { PackagesProps } from "../types/packages";

import Jalapao from "../assets/images/packages/jalapao/jalapao.png";
import JalapaoAgoL from "../assets/images/packages/jalapao/JalapaoAgoL.png";

import Jalapao2 from "../assets/images/packages/jalapao/jalapao2.png";
import JalapaoSepL from "../assets/images/packages/jalapao/JalapaoSepL.png";

import Egito from "../assets/images/packages/egito/Egito.png";
import EgitoL from "../assets/images/packages/egito/EgitoL.png";

import Newyork from "../assets/images/packages/newyork/newyork.png";
import NewyorkL from "../assets/images/packages/newyork/newyorkL.png";

import Rockinrio from "../assets/images/packages/rockinrio/rockinrio.png";
import RockinrioL from "../assets/images/packages/rockinrio/rockinrioL.png";

import Rotaemocoes from "../assets/images/packages/rotaemocoes/rotaemocoes.png";
import RotaemocoesL from "../assets/images/packages/rotaemocoes/rotaemocoesL.png";

import Triangulooeste from "../assets/images/packages/triangulooeste/triangulooeste.png";
import TriangulooesteL from "../assets/images/packages/triangulooeste/triangulooesteL.png";

import Africa from "../assets/images/packages/africa/africa.png";
import AfricaL from "../assets/images/packages/africa/africaL.png";

import Paris from "../assets/images/packages/paris/paris.png";
import ParisL from "../assets/images/packages/paris/parisL.png";

import Roma from "../assets/images/packages/roma/roma.png";
import RomaL from "../assets/images/packages/roma/romaL.png";

import Australia from "../assets/images/packages/australia/australia.png";
import AustraliaL from "../assets/images/packages/australia/australiaL.png";

import Thermas from "../assets/images/packages/thermas/thermas.png";
import ThermasL from "../assets/images/packages/thermas/thermasL.png";

export enum SlideType {
  rodoviario,
  nacional,
  internacional,
}

export const packages: PackagesProps[] = [
  {
    type: SlideType.nacional,
    title: "Jalapão em Agosto",
    period: "7 noites",
    initialDate: "15/08/2024",
    finalDate: "22/09/2024",
    parcelValue: 499.0,
    qtdParcel: 10,
    entry: 0,
    value: 4990,
    include: ["Aéreo", "Hotel", "Transporte", "Seguro"],
    description: "",
    policy: "",
    image: Jalapao,
    lamina: JalapaoAgoL,
  },
  {
    type: SlideType.rodoviario,
    title: "Excursão Thermas Water Park - São Pedro - SP",
    period: "1 noites",
    initialDate: "14/07/2024",
    finalDate: "14/07/2024",
    parcelValue: 125.0,
    qtdParcel: 2,
    entry: 125.0,
    value: 250,
    include: [
      "Transporte ida e volta",
      "Ingresso de entrada no parque+show",
      "Guia Agência Martins Viagens",
      "Seguro Viagem",
    ],
    description: "",
    policy: "",
    image: Thermas,
    lamina: ThermasL,
  },
  {
    type: SlideType.nacional,
    title: "Rota das emoções",
    period: "8 noites",
    initialDate: "05/09/2024",
    finalDate: "13/09/2024",
    parcelValue: 589.0,
    qtdParcel: 10,
    entry: 0,
    value: 5890,
    include: ["Aéreo", "Hotel", "Transporte", "Seguro"],
    description: "",
    policy: "",
    image: Rotaemocoes,
    lamina: RotaemocoesL,
  },
  {
    type: SlideType.internacional,
    title: "Triângulo do Oeste",
    period: "6 noites",
    initialDate: "18/08/2024",
    finalDate: "24/08/2024",
    parcelValue: 249.0,
    qtdParcel: 10,
    entry: 0,
    value: 2490.0,
    include: ["Hotel", "Traslados + Passeios", "Seguro viagem"],
    description: "",
    policy: "",
    image: Triangulooeste,
    lamina: TriangulooesteL,
  },
  {
    type: SlideType.nacional,
    title: "Rock in Rio 2024",
    period: "2 noites",
    initialDate: "20/09/2024",
    finalDate: "22/09/2024",
    parcelValue: 469.0,
    qtdParcel: 10,
    entry: 0,
    value: 4690.0,
    include: ["Hotel", "Transporte", "ingresso", "Seguro viagem"],
    description: "",
    policy: "",
    image: Rockinrio,
    lamina: RockinrioL,
  },

  {
    type: SlideType.internacional,
    title: "Egito",
    period: "11 noites",
    initialDate: "27/10/2024",
    finalDate: "07/11/2024",
    parcelValue: 379.0,
    qtdParcel: 10,
    entry: 0,
    value: 3790.0,
    include: ["Aéreo", "Hotel", "Transporte", "Seguro viagem"],
    description: "",
    policy: "",
    image: Egito,
    lamina: EgitoL,
  },
  {
    type: SlideType.internacional,
    title: "Reveillon New York",
    period: "7 noites",
    initialDate: "26/12/2024",
    finalDate: "02/01/2025",
    parcelValue: 279.0,
    qtdParcel: 10,
    entry: 0,
    value: 2790.0,
    include: ["Aéreo", "Hotel", "Transporte", "Seguro viagem"],
    description: "",
    policy: "",
    image: Newyork,
    lamina: NewyorkL,
  },
  {
    type: SlideType.nacional,
    title: "Jalapão em Setembro",
    period: "7 noites",
    initialDate: "02/09/2024",
    finalDate: "09/09/2024",
    parcelValue: 499.0,
    qtdParcel: 10,
    entry: 0,
    value: 4990.0,
    include: ["Aéreo", "Hotel", "Transporte", "Seguro viagem"],
    description: "",
    policy: "",
    image: Jalapao2,
    lamina: JalapaoSepL,
  },
  {
    type: SlideType.internacional,
    title: "África do Sul",
    period: "6 noites",
    initialDate: "10/10/2024",
    finalDate: "16/10/2024",
    parcelValue: 149.0,
    qtdParcel: 10,
    entry: 0,
    value: 1490.0,
    include: ["Hotel", "Traslados + Passeios", "Seguro viagem"],
    description: "",
    policy: "",
    image: Africa,
    lamina: AfricaL,
  },
  {
    type: SlideType.internacional,
    title: "Australia",
    period: "6 noites",
    initialDate: "07/09/2024, 05/10/2024 , 26/10/2024",
    finalDate: "",
    parcelValue: 479.0,
    qtdParcel: 10,
    entry: 0,
    value: 4790.0,
    include: ["Hotel", "Traslados + Passeios", "Seguro viagem"],
    description: "",
    policy: "",
    image: Australia,
    lamina: AustraliaL,
  },
  {
    type: SlideType.internacional,
    title: "Paris",
    period: "7 noites",
    initialDate: "27/02/2025",
    finalDate: "06/03/2025",
    parcelValue: 229.0,
    qtdParcel: 10,
    entry: 0,
    value: 2290.0,
    include: ["Hotel", "Traslados + Passeios", "Seguro viagem"],
    description: "",
    policy: "",
    image: Paris,
    lamina: ParisL,
  },

  {
    type: SlideType.internacional,
    title: "Roma",
    period: "7 noites",
    initialDate: "19/10/2024",
    finalDate: "",
    parcelValue: 346.0,
    qtdParcel: 10,
    entry: 0,
    value: 3460.0,
    include: ["Hotel", "Traslados + Passeios", "Seguro viagem"],
    description: "",
    policy: "",
    image: Roma,
    lamina: RomaL,
  },
];
