export const kekData = {
  cellphone: "+5519994153235",
  cellphoneFormat: "(19) 99415-3235",
  cellphone2: "+5519994153235",
  cellphoneFormat2: "(19) 99415-3235",
  email: "martinsviagens.excursoes@gmail.com",
  whatsapp: "https://api.whatsapp.com/send?phone=5519994153235",
  address: {
    street: "Rua João Correa Cezar",
    neighborhood: "Parque Valença 2",
    number: "24",
    city: "Campinas",
    state: "SP",
    cep: "13058-568",
    link: "https://maps.app.goo.gl/YKiGitJbesm6siJq6",
  },
};

export const sendWhatsAppMessage = (trip: string) => {
  const currentHour = new Date().getHours();
  const greeting =
    currentHour < 12 ? "Bom dia" : currentHour < 18 ? "Boa tarde" : "Boa noite";
  console.log(greeting);
  return `${kekData.whatsapp}&text=${greeting}, gostaria de saber mais do pacote ${trip}`;
};
