import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

type LinkProps = {
  secondary: boolean;
  fontSize?: number;
  width?: string;
};

export const Link = styled.a<LinkProps>`
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 24px 0;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "24px")};
  width: ${({ width }) => (width ? width : "auto")};
  color: ${({ secondary }) => (secondary ? COLORS.PRIMARY : COLORS.WHITE)};
  background-color: ${({ secondary }) =>
    secondary ? COLORS.WHITE : COLORS.PRIMARY};

  @media (max-width: 1400px) {
    font-size: ${({ fontSize }) => (fontSize ? 16 : 20) + "px"};
    padding: 18px 0;
    width: ${({ width }) => (width ? "184px" : "auto")};
  }

  @media (max-width: 950px) {
    width: auto;
  }
`;
