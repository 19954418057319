import { PackagesProps } from "../../types/packages";
import { BackgroundModal, CloseModal, Infos, Lamina, SaibaMais } from "./style";
import X from "../../assets/images/close.svg";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { sendWhatsAppMessage } from "../../mocks/kekData";

type ModalProps = {
  selectedPackage?: PackagesProps;
  open?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<PackagesProps | undefined>>;
};

export default function Modal({
  selectedPackage,
  open = false,
  setOpen,
}: ModalProps) {
  return open ? (
    <>
      <BackgroundModal>
        <CloseModal
          src={X}
          alt="x"
          width={32}
          onClick={() => setOpen(undefined)}
        />
        <Infos>
          <Lamina src={selectedPackage?.lamina} alt="lâmina" />
          <SaibaMais
            href={sendWhatsAppMessage(selectedPackage?.title ?? "")}
            target="_blank"
          >
            <i className="fa-brands fa-whatsapp"></i> SAIBA MAIS
          </SaibaMais>
        </Infos>
      </BackgroundModal>
    </>
  ) : (
    <></>
  );
}
