import React, { useState } from "react";
import {
  Box,
  Head,
  Logo,
  MenuMobile,
  OpenMenu,
  Option,
  Options,
} from "./style";
import MartinsLogo from "../../assets/images/logo.svg";
import MenuIcon from "../../assets/images/menu.svg";
import MenuClose from "../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Container from "../Container";
import { kekData } from "../../mocks/kekData";

type HeaderProps = {
  notHome?: boolean;
  topRef?: React.MutableRefObject<any>;
  packagesRef?: React.MutableRefObject<any>;
  aboutRef?: React.MutableRefObject<any>;
  testRef?: React.MutableRefObject<any>;
};

export default function Header({
  notHome = false,
  topRef,
  packagesRef,
  aboutRef,
  testRef,
}: HeaderProps) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  function toggleMenu() {
    setOpenMenu(!openMenu);
  }

  function handleMobileMenuClick(func?: React.MutableRefObject<any>) {
    func?.current.scrollIntoView({
      behavior: "smooth",
    });
    setOpenMenu(false);
  }

  return (
    <Head notHome={notHome}>
      <Container>
        <Box>
          <Logo
            src={MartinsLogo}
            alt="Logo"
            onClick={() => {
              if (!notHome) {
                topRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              } else {
                navigate("/");
              }
            }}
          />
          <Options>
            <Option
              notHome={notHome}
              onClick={() => {
                packagesRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <i className="fa-solid fa-suitcase"></i> Nossos pacotes
            </Option>
            <Option
              notHome={notHome}
              onClick={() => {
                aboutRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <i className="fa-solid fa-users"></i> Quem somos
            </Option>
            <Option
              notHome={notHome}
              onClick={() => {
                testRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <i className="fa-solid fa-comments"></i> Depoimentos
            </Option>
            <Option
              notHome={notHome}
              onClick={() => {
                window.open(kekData.whatsapp, "_blank");
              }}
            >
              <i className="fa-solid fa-phone"></i> Fale conosco
            </Option>
          </Options>
          <OpenMenu
            notHome={notHome}
            src={!openMenu ? MenuIcon : MenuClose}
            alt="open menu"
            onClick={() => toggleMenu()}
            loading="lazy"
          />
          {!notHome && (
            <MenuMobile isOpen={openMenu}>
              <button onClick={() => handleMobileMenuClick(packagesRef)}>
                <i className="fas fa-tags"></i> Nossos pacotes
              </button>
              <button onClick={() => handleMobileMenuClick(aboutRef)}>
                <i className="fas fa-info-circle"></i> Quem somos
              </button>
              <button onClick={() => handleMobileMenuClick(testRef)}>
                <i className="fas fa-comment-dots"></i> Depoimentos
              </button>
              <button onClick={() => handleMobileMenuClick(testRef)}>
                <i className="fas fa-envelope"></i> Fale conosco
              </button>
            </MenuMobile>
          )}
        </Box>
      </Container>
    </Head>
  );
}
