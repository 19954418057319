import Container from "../Container";
import { Flex, Info, Infos, LogoImg, Section } from "./style";
import Logo from "../../assets/images/logo.svg";
import Copyright from "../Copyright";
import Insta from "../../assets/images/redes/instagram.svg";
import Face from "../../assets/images/redes/facebook.svg";

type FooterProps = {
  topRef?: React.MutableRefObject<any>;
};

export default function Footer({ topRef }: FooterProps) {
  return (
    <>
      <Section>
        <Container>
          <Flex>
            <LogoImg
              src={Logo}
              alt="K&K Logo"
              onClick={() => {
                topRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
            <Infos>
              <Info>
                <b>Atendimento:</b>
                <br />
                Segunda à sexta 09h às 19h
              </Info>
              <Info>
                <span>
                  <b>Redes sociais:</b>
                  <br />
                  <a href="https://www.instagram.com/martinsviagens_excursoes/" target="_blank" rel="noreferrer">
                    <img src={Insta} alt="instagram" width={28} />
                  </a>
                  <a href="https://www.facebook.com/people/Martins-Viagens-e-Excurs%C3%B5es/100075911662425/" target="_blank" rel="noreferrer">
                    <img src={Face} alt="instagram" width={28} />
                  </a>
                </span>
              </Info>
            </Infos>
          </Flex>
        </Container>
      </Section>
      <Copyright />
    </>
  );
}
