import { AboutInfo, Flex } from "./style";
import AboutImg from "../../../assets/images/about.png";
import { kekData } from "../../../mocks/kekData";
import ContactLink from "../../../components/ContactLink";
import { SectionProps } from "../../../types/section";

export default function About({ referance }: SectionProps) {
  return (
    <Flex ref={referance}>
      <AboutInfo>
        <div>
          <h1>Sobre nós</h1>
          <p>
            Fundada em 2021, nossa agência de viagens se dedica a oferecer as
            melhores ofertas e experiências inesquecíveis para nossos clientes.
            Com uma equipe apaixonada por viagens e comprometida com a
            excelência, buscamos destinos únicos e personalizados para atender
            às suas necessidades e desejos. Nosso objetivo é proporcionar
            viagens memoráveis, com conforto, segurança e preços competitivos.
            Confie em nós para transformar seus sonhos de viagem em realidade!
          </p>
          <ContactLink
            title="saiba mais"
            link={kekData.whatsapp}
            blank
            secondary
          />
        </div>
      </AboutInfo>
      <img src={AboutImg} alt="about image" />
    </Flex>
  );
}
