import { SlideType } from "../../../mocks/packages";
import { PackagesProps } from "../../../types/packages";
import {
  Info,
  InfoTitle,
  SlideContainer,
  SlideImage,
  SlideInfo,
} from "./style";

type SlidePackegesProps = {
  pack: PackagesProps;
  props: any;
  setPackage: (p: PackagesProps) => void;
};

export default function Slide({ props, pack, setPackage }: SlidePackegesProps) {
  const { index, ...otherProps } = props;

  return (
    <div>
      <SlideContainer {...otherProps} onClick={() => setPackage(pack)}>
        <SlideImage backgroundImage={pack.image} />
        <SlideInfo>
          <InfoTitle>{pack.title}</InfoTitle>
          <Info>
            {pack.period}
            <br />
            Saída: {pack.initialDate}
          </Info>
          <Info>
            A partir de <b>{pack.qtdParcel}x</b> de
            <br />
            <b>
              {pack.type === SlideType.internacional ? "USD " : "R$ "}
              <span>
                {Intl.NumberFormat("pt-br", {
                  minimumFractionDigits: 2,
                }).format(pack.parcelValue)}
              </span>
            </b>
          </Info>
          <Info className="totalValue">
            Total à vista por:{" "}
            <b>
              {pack.type === SlideType.internacional ? "USD " : "R$ "}
              {Intl.NumberFormat("pt-br", {
                minimumFractionDigits: 2,
              }).format(pack.value)}
            </b>
          </Info>
        </SlideInfo>
      </SlideContainer>
    </div>
  );
}
