import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

type TabsProps = {
  qtd: string[];
};

type TabProps = {
  active?: boolean;
};

export const Tabs = styled.div<TabsProps>`
  width: 100%;
  margin: 68px 0;
  display: grid;
  grid-template-columns: ${({ qtd }) =>
    qtd.length > 0
      ? qtd.map(() => {
          const w = 100 / qtd.length - 1;
          return `${Math.round(w)}%`;
        })
      : "100%"};
  gap: 2%;
  padding-bottom: 12px;

  @media (max-width: 1400px) {
    margin: 48px 0;
  }

  @media (max-width: 700px) {
    grid-template-columns: ${({ qtd }) =>
      qtd.length > 0 ? qtd.map(() => "65%") : "100%"};
    overflow-x: auto;
    gap: 8px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Tab = styled.button<TabProps>`
  all: unset;
  font-size: 22px;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  border-bottom: 4px solid
    ${({ active }) => (active ? COLORS.SECONDARY : COLORS.GRAY)};
  padding-bottom: 8px;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 18px;
  }
`;
