import styled from "styled-components";

export const BackgroundModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseModal = styled.img`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 9999;
  margin: 12px 12px 0 0;
  filter: invert(100%) sepia(0%) saturate(7210%) hue-rotate(11deg)
    brightness(126%) contrast(90%);

  @media (max-width: 500px) {
    margin: 4px 4px 0 0;
  }
`;

export const Infos = styled.div`
  height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Lamina = styled.img`
  height: 85vh;

  @media (max-width: 500px) {
    height: auto;
    width: 90%;
    margin: 18px auto 0 auto;
  }
`;

export const SaibaMais = styled.a`
  all: unset;
  height: 5vh;
  cursor: pointer;
  background-color: #4bb763;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 6px;
  }

  @media (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
    height: 48px;
  }
`;
