import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

type HeadProps = {
  notHome: boolean;
};

type MenuMobileProps = {
  isOpen?: boolean;
};

export const Head = styled.div<HeadProps>`
  width: 100%;
  background-color: ${({ notHome }) =>
    !notHome ? "rgba(255, 255, 255, 0.85);" : COLORS.GRAY};
  position: ${({ notHome }) => (!notHome ? "fixed" : "static")};
  z-index: 999;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
`;

export const Box = styled.div`
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  a {
    margin-left: 22px;
  }

  @media (max-width: 1400px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 148px;
  cursor: pointer;

  @media (max-width: 1400px) {
    width: 120px;
  }

  @media (max-width: 950px) {
    width: 100px;
  }
`;

export const Option = styled.button<HeadProps>`
  all: unset;
  cursor: pointer;
  padding: 8px;
  margin: 0 8px;
  color: ${COLORS.PRIMARY};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  font-size: 22px;
  display: ${({ notHome }) => (notHome ? "none" : "block")};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    padding: 8px 8px 6px 8px;
    border-bottom: 2px solid ${COLORS.PRIMARY};
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    margin: 0 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const OpenMenu = styled.img<HeadProps>`
  display: none;
  cursor: pointer;
  width: 48px;
  padding: 8px 0px 8px 8px;
  filter: invert(9%) sepia(66%) saturate(6666%) hue-rotate(226deg)
    brightness(106%) contrast(98%);

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 700px) {
    width: 40px;
  }
`;

export const MenuMobile = styled.div<MenuMobileProps>`
  display: none;
  width: 85%;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  margin-top: 115px;
  right: 0;
  height: 100vh;
  margin-right: ${({ isOpen }) => (isOpen ? "0px" : "-1200px")};
  transition: 0.4s all;
  z-index: 99;

  @media (max-width: 1200px) {
    display: flex;
  }

  @media (max-width: 950px) {
    margin-top: 99px;
  }

  a,
  button {
    all: unset;
    cursor: pointer;
    text-align: center;
    padding: 16px 8px;
    color: ${COLORS.PRIMARY};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    border-bottom: 1px solid ${COLORS.PRIMARY};
    font-size: 24px;

    @media (max-width: 700px) {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    display: none;

    @media (max-width: 950px) {
      display: block;
    }
  }
`;
