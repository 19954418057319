import { SlideBox } from "./style";

type SlideProps = {
  testimony: string;
  props: any;
};

export default function Slide({ testimony, props }: SlideProps) {
  const name = testimony?.split(".")[0].split("/")[3];
  return (
    <SlideBox>
      <img src={testimony} alt={`testimony: ${name}`} />
    </SlideBox>
  );
}
