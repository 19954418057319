import styled from "styled-components";
import Background from "../../../assets/images/background.png";

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 950px) {
    height: 580px;
  }

  @media (max-width: 700px) {
    height: 540px;
    background-position: left;
  }
`;

export const CallToActionContainer = styled.div`
  width: 40%;
  background: radial-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0) 100%
  );
  padding: 18px;
  border-radius: 100%;
  box-shadow: 0px 0px 51px 41px rgb(255 255 255 / 10%);

  @media (max-width: 950px) {
    width: 100%;
    padding: 0;
  }
`;

export const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
  color: #0331a0;
  font-weight: bold;
  @media (max-width: 950px) {
    font-size: 1.8em;
  }
`;

export const Description = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #0331a0;
`;

export const Button = styled.button`
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 15px 30px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 480px;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 1400px) {
    width: 420px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 15px 0;
  }
`;
